import ApiService from '../api-service';

class AlertApiService extends ApiService {
  getAll = async (params) => await this.get('', params).then((res) => res.data);
  
  create = async (data) => await this.post('', data).then((res) => res.data);
  
  update = async (id, data) => await this.put(`/${id}`, data).then((res) => res.data);
  
  remove = async (id) => await this.delete(`/${id}`).then((res) => res.data);
}

export const AlertApi = new AlertApiService('alert');
