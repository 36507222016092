import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import RouteEnum from '../enums/route.enum';
import Config from "../../components/pages/config";

const NotFoundPage = lazy(() => import('../../components/pages/not-found'));
const SignInPage = lazy(() => import('../../components/auth/sign-in'));
const UploadingFilesPage = lazy(() => import('../../components/pages/uploading-files'));
const ParsedFilesPage = lazy(() => import('../../components/pages/parsed-files'));
const FilesHistoryPage = lazy(() => import('../../components/pages/files-history'));
const EditProfilePage = lazy(() => import('../../components/pages/edit-profile'));
const ConfigPage = lazy(() => import('../../components/pages/config'));

const routes = [
  {
    path: RouteEnum.HOME,
    Component: () => <Navigate to={RouteEnum.UPLOADING_FILES} replace />,
  },
  {
    path: RouteEnum.LOGIN,
    Component: SignInPage,
    publicOnly: true,
    redirectTo: RouteEnum.UPLOADING_FILES,
  },
  {
    path: RouteEnum.UPLOADING_FILES,
    Component: UploadingFilesPage,
    authOnly: true,
    redirectTo: RouteEnum.LOGIN,
  },
  {
    path: RouteEnum.PARSED_FILES,
    Component: ParsedFilesPage,
    authOnly: true,
    redirectTo: RouteEnum.LOGIN,
  },
  {
    path: RouteEnum.PARSED_FILES_VALIDATION,
    Component: () => <ParsedFilesPage withTotals />,
    authOnly: true,
    redirectTo: RouteEnum.LOGIN,
  },
  {
    path: RouteEnum.FILES_HISTORY,
    Component: () => <ParsedFilesPage history />,
    authOnly: true,
    redirectTo: RouteEnum.LOGIN,
  },
  {
    path: RouteEnum.EDIT_PROFILE,
    Component: EditProfilePage,
    authOnly: true,
    redirectTo: RouteEnum.LOGIN,
  },
  {
    path: RouteEnum.CONFIG,
    Component: ConfigPage,
    authOnly: true,
    redirectTo: RouteEnum.LOGIN,
  },
  { path: RouteEnum.NOT_FOUND, Component: NotFoundPage },
  { path: '*', Component: () => <Navigate to={RouteEnum.NOT_FOUND} replace /> },
];

export default routes;
