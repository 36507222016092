import { useDispatch } from 'react-redux';
import {
  clearSnackbar as clearSnackbarAction,
  showErrorSnackbar,
  showInfoSnackbar,
  showSuccessSnackbar,
} from '../store/actions/ui';

const useSnackbar = () => {
  const dispatch = useDispatch();

  const successSnackbar = (message) => {
    dispatch(showSuccessSnackbar(message));
  };

  const errorSnackbar = (message) => {
    dispatch(showErrorSnackbar(message));
  };

  const infoSnackbar = (message) => {
    dispatch(showInfoSnackbar(message));
  };

  const clearSnackbar = () => {
    dispatch(clearSnackbarAction());
  };

  return {
    successSnackbar,
    errorSnackbar,
    infoSnackbar,
    clearSnackbar,
  };
};

export default useSnackbar;
