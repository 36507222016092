import React, {useState, useEffect, useRef} from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box, TextField, IconButton,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import useSnackbar from "../../../hooks/use-snackbar.hook";
import {AlertApi} from "../../../services/api/alert";
import {DeleteRounded, EditRounded} from "@mui/icons-material";
import Footer from "../../layouts/footer";

const Config = () => {
  const [alerts, setAlerts] = useState([]);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({word: ''});
  const [editingId, setEditingId] = useState(null);
  const containerRef = useRef(null);
  const [page, setPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const {t} = useTranslation();
  const {errorSnackbar} = useSnackbar();

  useEffect(() => {
    fetchAlerts();
  }, []);

  useEffect(() => {
    if (page > 1) {
      fetchAlerts(true);
    }
  }, [page]);

  const fetchAlerts = async (append = false) => {
    setLoading(true);
    if (!append) {
      setPage(1);
    }
    try {
      const { results, next } = await AlertApi.getAll({ page: append ? page : 1 })
      setAlerts(append ? [...alerts, ...results] : results);
      setIsLastPage(!next);
    } catch (e) {
      errorSnackbar(e?.response?.data?.message || t('config.fetch.error'));
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = (alert = null) => {
    if (alert) {
      setFormData({ word: alert.word });
      setEditingId(alert.id);
    } else {
      setFormData({word: ''});
      setEditingId(null);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const handleSubmit = async () => {
    try {
      if (editingId) {
        await AlertApi.update(editingId, formData);
      } else {
        await AlertApi.create(formData);
      }
      handleClose();
      await fetchAlerts();
    } catch (e) {
      errorSnackbar(e?.response?.data?.word || t('config.add.error'));
    }
  };

  const handleDelete = async (id) => {
    try {
      await AlertApi.remove(id);
      await fetchAlerts();
    } catch (e) {
      errorSnackbar(e?.response?.data?.message || t('config.remove.error'));
    }
  };

  const handleScroll = () => {
    if (containerRef.current) {
      const scrollTop = containerRef.current.scrollTop;
      const scrollHeight = containerRef.current.scrollHeight;
      const clientHeight = containerRef.current.clientHeight;

      if (scrollTop && scrollTop + clientHeight >= scrollHeight && !loading && !isLastPage) {
        setPage((prevPage) => prevPage + 1);
      }
    }
  }

  return (
    <Container
      ref={containerRef}
      onScroll={handleScroll}
      sx={{mt: 12, mb: 1, overflowY: 'auto', maxHeight: '100vh'}}
      component="main"
      maxWidth="xxl"
    >
      <Box maxWidth={900}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            {t('config.title')}
          </Typography>
          <Button variant="contained" color="primary" onClick={() => handleOpen()}>
            {t('config.dialog.title')}
          </Button>
        </Box>
        <TableContainer component={Paper} sx={{mt: 3}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>{t('config.word')}</TableCell>
                <TableCell>{t('actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {alerts.map((alert) => (
                <TableRow key={alert.id}>
                  <TableCell>{alert.id}</TableCell>
                  <TableCell>{alert.word}</TableCell>
                  <TableCell>
                    <IconButton color="warning" onClick={() => handleOpen(alert)}>
                      <EditRounded />
                    </IconButton>
                    <IconButton color="error" onClick={() => handleDelete(alert.id)}>
                      <DeleteRounded />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{t('config.dialog.title')}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="word"
            label={t('config.word')}
            type="text"
            fullWidth
            value={formData.word}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleClose}>{t('cancel')}</Button>
          <Button variant="contained" onClick={handleSubmit} color="primary">{t('save')}</Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </Container>
  );
};

export default Config;
